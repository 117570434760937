<template>
  <!-- Login form with email and password -->
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-6 offset-md-3">
        <h1 class="text-center">
          PARTY REVIVE
        </h1>
        <form @submit.prevent="login" method="POST">
          <p class="text-danger" v-if="invalidLogin">Email o contraseña incorrectos</p>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" name="email" v-model="email" required>
          </div>
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" class="form-control" id="password" name="password" v-model="password" required>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      invalidLogin: false
    }
  },
  methods: {
    login () {
      this.axios.post('/session', {
        username: this.email,
        password: this.password
      }).then((response) => {
        const token = response.data.token
        if (!token) {
          this.invalidLogin = true
        } else {
          this.$cookies.set('token', response.data.token)
          this.$cookies.set('role', response.data.role)
          if(response.data.role === 'Admin') {
            this.$router.push('/admin')
          } else if (response.data.role === 'TicketChec') {
            this.$router.push('/admin?role=TicketChec')
          } else {
            window.location.href = '/perfil'
          }
        }
      })
    }
  }
}
</script>

<style>

</style>